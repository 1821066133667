import DynamicLink from 'components/dynamiclink/DynamicLink'
import { FC, useEffect } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'
import { useRouter } from 'next/router'

import { filteredNavItemsForNavFooter } from '../../../utils/navigation-items'
import OUTextLogo from '../../icons/OU_TextLogo'
import { LanguagePicker } from '../../languagepicker/LanguagePicker'
// import Social from '../../social/Social'
import ContentContainer from '../../styled/ContentContainer'

import styles from './Footer.module.scss'
import InternalLink from 'components/internallink/InternalLink'
import { useLink } from 'context/LinkContext'
import Image from 'next/image'
import { PageAlternateLink } from 'context/LinkContext'
import SocialMedia, { SocialMediaLink } from 'components/builder_components/social_media/SocialMedia'

type PropTypes = {
  blok?: StoryblokContent
  links: StoryblokContent
  pageAlternates?: PageAlternateLink[]
}

const Footer: FC<PropTypes> = ({ blok, links, pageAlternates }) => {
  const navItems = filteredNavItemsForNavFooter(links)
  const router = useRouter()
  const language = router.locale.length > 2 ? router.locale.slice(0, 2) : 'en'
  let url = 'https://once.upon.se.kb.kundo.se/js/kundo-knowledge-widget.js'
  switch (language) {
    case 'en':
      url = 'https://once.upon.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'da':
      url = 'https://upon.once.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'fr':
      url = 'https://onceupon2.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'it':
      url = 'https://oonce.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'nl':
      url = 'https://once.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'nb':
      url = 'https://once.upn.se.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'es':
      url = 'https://es.once.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'sv':
      url = 'https://onceupon1.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'de':
      url = 'https://once.upon.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    default:
      url = 'https://once.upon.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
  }

  const useScript = (url): void => {
    useEffect(() => {
      // When we change language Kundo seems to create a new div container each time.
      // We need to remove it for it to work.
      const kundoWidgetElement = document.getElementById('kundo-knowledge-widget')
      if (kundoWidgetElement !== undefined && kundoWidgetElement !== null) {
        kundoWidgetElement.remove()
      }

      const script = document.createElement('script')

      script.src = url
      script.async = true

      document.body.appendChild(script)

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script)
        }
      }
    }, [url])
  }

  useScript(url)
  const { getLocalePath } = useLink()

  const socialMediaLinks: SocialMediaLink[] = [
    {
      label: 'Link to Instagram',
      link: 'https://www.instagram.com/onceuponapp/',
      icon: 'instagram',
    },
    {
      label: 'Link to Pinterest',
      link: 'https://www.pinterest.se/onceuponpublishing/',
      icon: 'pinterest',
    },
    {
      label: 'Link to Facebook',
      link: 'https://www.facebook.com/onceuponpublishing',
      icon: 'facebook',
    },
  ]

  return (
    <footer style={{ zIndex: 6 }}>
      <div className={styles.bcorpContainer}>
        <div className={styles.bcorpLogo}>
          <InternalLink href={getLocalePath('/about-us/sustainability', router.locale)}>
            <Image src={'/images/bCorp_Logo.png'} width={35.6} height={60} alt={'B Corp Logo'} />
          </InternalLink>
        </div>
      </div>
      <ContentContainer className={styles.container}>
        <div className={styles.languageContainer}>
          {/* <LanguagePicker className={styles.languagePicker} /> */}
          <LanguagePicker className={styles.languagePicker} pageAlternates={pageAlternates} />
        </div>

        <div className={styles.navContainer}>
          <nav className={styles.nav}>
            {navItems.map((navItem: StoryblokContent) => (
              <DynamicLink key={navItem._uid} linkObject={navItem.link}>
                {navItem.name}
              </DynamicLink>
            ))}
          </nav>
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.logo}>
            <OUTextLogo width={170} height={55} fill="#FFFFFF" />
          </div>

          <p className={styles.about}>
            {blok.company_name}, {blok.company_address}, {blok.company_mail}, {blok.copyright_text}
          </p>

          {/* <Social className={styles.social} /> */}
          <SocialMedia className={styles.social} iconColor={'white'} socialLink={socialMediaLinks} />
        </div>
      </ContentContainer>
    </footer>
  )
}

export default Footer
